import {Component, Input, OnInit} from '@angular/core';
import {PaginatorDot} from '@app/shared/types/classes';

@Component({
  selector: 'app-paginator',
  template: `
    <div class="layout__row align__center__center flex-gap-16px">
      <ng-container
        *ngFor="let page of pages; let index = index"
      >
        <div
          *ngIf="page.display"
          class="page"
          [ngClass]="{
            'page--farther': page.farthest,
            'page--far': page.far,
            'page--selected': page.selected
            }"
        ></div>
      </ng-container>
    </div>
  `,
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {
  @Input('numberPages') set _numberPages(numberPages: number) {
    if(numberPages){
      this.numberPages = numberPages;
      this.initData();
    }
  }

  @Input('currentPage') set _currentPage(currentPage: number) {
    if (this.currentPage === undefined) {
      this.currentPage = currentPage;
      return;
    }
    this.previousSelectedPage = this.currentPage;
    this.currentPage = currentPage;

    this.updateDots();
  }

  pages: PaginatorDot[];
  currentPage: number;
  numberPages: number;

  private previousSelectedPage: number;
  private mainDotsWindow: number[];

  constructor() {
  }

  ngOnInit(): void {

  }

  private initData(): void {
    this.pages = Array(this.numberPages).fill(0).map(() => new PaginatorDot());
    this.pages[0].main = true;
    this.pages[0].selected = true;

    this.mainDotsWindow = [0];

    if (this.numberPages > 1) {
      this.pages[1].main = true;
      this.mainDotsWindow.push(1);
    }
    if (this.numberPages > 2) {
      this.pages[2].main = true;
      this.mainDotsWindow.push(2);
    }
    if (this.numberPages > 3) {
      this.pages[3].far = true;
    }
    if (this.numberPages > 4) {
      this.pages[4].farthest = true;
    }
  }

  private updateDots(): void {
    this.pages[this.previousSelectedPage].selected = false;
    this.pages[this.currentPage].selected = true;

    const index = this.mainDotsWindow.findIndex((mainDot: number) => mainDot === this.currentPage);
    if (index === -1) {
      let newFarDotLeft;
      let newFarthestDotLeft;
      let newFarDotRight;
      let newFarthestDotRight;

      if (this.previousSelectedPage < this.currentPage) {
        newFarDotLeft = this.mainDotsWindow[0];
        if (newFarDotLeft > 0) {
          newFarthestDotLeft = newFarDotLeft - 1;
        }
        if (newFarDotLeft > 1) {
          this.pages[newFarthestDotLeft - 1].farthest = false;
        }
        this.mainDotsWindow.shift();
        this.mainDotsWindow.push(this.currentPage);

        if (this.currentPage < this.numberPages - 1) {
          newFarDotRight = this.currentPage + 1;
        }
        if (this.currentPage < this.numberPages - 2) {
          newFarthestDotRight = this.currentPage + 2;
        }
      } else {
        newFarDotRight = this.mainDotsWindow[2];
        if (newFarDotRight < this.numberPages - 1) {
          newFarthestDotRight = newFarDotRight + 1;
        }
        if (newFarDotRight < this.numberPages - 2) {
          this.pages[newFarthestDotRight + 1].farthest = false;
        }
        this.mainDotsWindow.pop();
        this.mainDotsWindow.unshift(this.currentPage);

        if (this.currentPage > 0) {
          newFarDotLeft = this.currentPage - 1;
        }

        if (this.currentPage > 1) {
          newFarthestDotLeft = newFarDotLeft - 1;
        }
      }

      this.updateOuterDots(newFarDotLeft, newFarthestDotLeft);
      this.updateOuterDots(newFarDotRight, newFarthestDotRight);

      this.pages[this.currentPage].main = true;
      this.pages[this.currentPage].far = false;
    }
  }

  private updateOuterDots(indexFar: number, indexFarthest): void {
    if (indexFar !== undefined) {
      this.pages[indexFar].farthest = false;
      this.pages[indexFar].far = true;
      this.pages[indexFar].main = false;
      if (indexFarthest !== undefined) {
        this.pages[indexFarthest].farthest = true;
        this.pages[indexFarthest].far = false;
      }
    }
  }

}
