import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-spinner',
  template: `
    <div
      class="full-width full-height layout__row align__center__center"
    >
      <mat-spinner color="accent" [diameter]="diameter"></mat-spinner>
    </div>
  `,
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
  @Input() diameter = 30;

  constructor() {}

  ngOnInit(): void {}
}
