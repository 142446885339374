import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';
import { Snackbar } from '@app/shared/types/classes';

@Component({
  selector: 'app-snackbar',
  template: `
    <div
      class="snackbar full-height full-width layout__column flex-gap-8px"
      [ngClass]="'snackbar--' + data.type"
    >
      <div
        class="full-width layout__row align__space-between__center"
      >
        <div
          class="full-height full-width layout__row align__start__center flex-gap-16px"
        >
          <mat-icon
            [svgIcon]="typeIconMap[data.type]"
            class="snackbar__icon"
          ></mat-icon>
          <div class="mat-body-1 snackbar__text">
            {{ label | translate | uppercase }}
          </div>
        </div>

        <button mat-icon-button (click)="onClose()" class="snackbar__icon">
          <mat-icon [svgIcon]="'close'"></mat-icon>
        </button>
      </div>

      <div
        class="snackbar__text snackbar__text--message full-width layout__row"
      >
        <span class="nunito-body-2">
          {{ data.message | translate }}
        </span>
      </div>
    </div>
  `,
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit {
  typeIconMap = {
    success: 'checkmark',
    info: 'info',
    error: 'alert_circle'
  };

  constructor(
    private snackBar: MatSnackBar,
    @Inject(MAT_SNACK_BAR_DATA)
    public data: Snackbar
  ) {}

  ngOnInit(): void {}

  get label(): string {
    return `snackbar.${this.data.type}`;
  }

  onClose(): void {
    this.snackBar.dismiss();
  }
}
