import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { MaterialModule } from './material/material.module';
import { ReactiveFormsModule } from '@angular/forms';

import * as fromComponents from './components';
import * as fromPipes from './pipes';
import * as fromDirectives from './directives';
import {ScrollDirective} from './directives';

@NgModule({
  declarations: [
    ...fromPipes.pipes,
    ...fromComponents.components,
    ...fromDirectives.directives
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    ReactiveFormsModule
  ],
  exports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    ReactiveFormsModule,
    ...fromPipes.pipes,
    ...fromComponents.components,
    ScrollDirective
  ]
})
export class SharedModule { }
