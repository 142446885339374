import { Component, Input, OnInit } from '@angular/core';
import { FloatLabelType } from '@angular/material/form-field';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-input',
  template: `
    <mat-form-field
      [color]="color"
      class="full-width"
      [floatLabel]="floatLabel"
      [appearance]="appearance"
    >
      <mat-label>
        {{label | translate}}
      </mat-label>
      <input
        matInput
        autocomplete="off"
        [placeholder]="placeholder | translate"
        [formControl]="control"
        [type]="'text'"
      />

      <mat-error *ngIf="control?.hasError('required'); else customError">
        {{ 'errors.required' | translate }}
      </mat-error>

      <ng-template #customError>
        <mat-error *ngIf="error; else controlError">
          {{ error | translate }}
        </mat-error>

        <ng-template #controlError>
          <mat-error *ngFor="let error of control?.errors | keyvalue">
            <ng-container *ngIf="displayError(error.key)">
              {{ error.value | translate }}
            </ng-container>
          </mat-error>
        </ng-template>
      </ng-template>
    </mat-form-field>
  `,
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {
  @Input() type = 'text';
  @Input() appearance = 'legacy';
  @Input() color = 'accent';
  @Input() label: string;
  @Input() placeholder: string;
  @Input() error: string;
  @Input() control: FormControl;
  @Input() floatLabel: FloatLabelType;

  constructor() {}

  ngOnInit(): void {}

  displayError(error: any): boolean {
    return !(error === 'required');
  }

}
