import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-footer',
  template: `
    <div class="footer page full-width full-height mat-body-1 light-text-color">
      <div class="layout__column flex-gap-16px ">
        <div class="layout__row footer__powered">
          <span>{{'footer.poweredBy' | translate}}&nbsp;</span>
          <span class="weight-600">
            <a [href]="'https://www.wearecain.com/'" [target]="'_blank'"
               class="layout__row align__start__center footer__link footer__link__cain">
              <div class="layout__row align__center__center footer__link__image__cain">
                <img [src]="'./assets/images/CainLogo.png'" class="footer__logo footer__logo__cain"/>
              </div>
              &nbsp;CAIN GROUP&nbsp;
            </a>
          </span>
          <span>&&nbsp;</span>
          <span class="weight-600">
            <a
              [href]="'https://www.linnify.com/'"
              [target]="'_blank'"
              (mouseenter)="linnifyLogo = './assets/images/LinnifyLogo.svg'"
              (mouseleave)="linnifyLogo = './assets/images/LinnifyLogoWhite.svg'"
              class="layout__row align__start__center footer__link"
            >
              <img [src]="linnifyLogo" class="footer__logo footer__logo__linnify"/>
              &nbsp;Linnify
            </a>
          </span>
        </div>
        <div class="layout__row flex-gap-8px">
          <a href="https://www.youtube.com/channel/UC0OjCfO82ZdcnQz6etYk2FA/videos" target="_blank"
             class="footer__link__image__yt">
            <img [src]="'./assets/images/Youtube.png'" alt="youtube" class="footer__media footer__media__yt"/>
          </a>
          <a href="https://www.instagram.com/50chefs/" target="_blank">
            <img [src]="'./assets/images/Instagram.png'" alt="instagram" class="footer__media"/>
          </a>
          <a href="https://www.facebook.com/50chefs" target="_blank">
            <img [src]="'./assets/images/Facebook.png'" alt="facebook" class="footer__media"/>
          </a>
          <a href="https://www.linkedin.com/company/50-chefs/" target="_blank ">
            <img [src]="'./assets/images/LinkedIn.png'" alt="linkedin" class="footer__media"/>
          </a>
        </div>
      </div>
      <div class="footer__links">
        <a [routerLink]="['/', 'agreements', 'terms-and-conditions']">{{'footer.terms' | translate | uppercase}}</a>
        <a [routerLink]="['/', 'agreements', 'privacy-policy']">{{'footer.privacy' | translate | uppercase}}</a>
      </div>
    </div>
  `,
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  linnifyLogo = './assets/images/LinnifyLogoWhite.svg';

  constructor() {
  }

  ngOnInit(): void {
  }

}
