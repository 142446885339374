import { Pipe, PipeTransform } from '@angular/core';
import { translations } from '@app/shared/translations';

@Pipe({
  name: 'translate'
})
export class TranslationPipe implements PipeTransform {
  transform(key: string, params?: Record<string, any>): string {
    if (!key) {
      return;
    }

    return (
      key.split('.').reduce((acc: any, curr: string) => {
        if (typeof acc[curr] === 'function') {
          return acc[curr](params);
        }
        return acc[curr];
      }, translations) || key
    );
  }
}
