import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class IconRegistryService {
  private readonly folder = 'assets/icons';

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {}

  private iconPath(icon: string): string {
    return `${this.folder}/${icon}.svg`;
  }

  addSvgIcons(): void {
    const iconDefinitions = {
      arrow: this.iconPath('arrow'),
      arrowDown: this.iconPath('ic_arrow'),
      checkmark: this.iconPath('checkmark'),
      close: this.iconPath('close'),
    };

    Object.keys(iconDefinitions).forEach(key => {
      this.iconRegistry.addSvgIcon(
        key,
        this.sanitizer.bypassSecurityTrustResourceUrl(iconDefinitions[key])
      );
    });
  }
}
