import {NgModule} from '@angular/core';

import {SharedModule} from '@app/shared/shared.module';

import * as fromContainers from './containers';
import * as fromComponents from './components';

@NgModule({
  declarations: [...fromContainers.containers, ...fromComponents.components],
  imports: [
    SharedModule
  ]
})
export class CoreModule {
}
