export class PaginatorDot {
  farthest: boolean;
  far: boolean;
  main: boolean;
  selected: boolean;

  get display(): boolean {
    return this.farthest || this.far || this.main;
  }
}
