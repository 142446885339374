export const translations = {
  hero: {
    description: $localize`:@@hero.description:A digital platform that enables remarkable Chefs to compete and collaborate, creating outstanding experiences that promote Dubai as the culinary capital of the world.`
  },
  experiences: {
    title: {
      glimpse: $localize`:@@experiences.title.glimpse:A glimpse into the`,
      future: $localize`:@@experiences.title.future:future`,
      of: $localize`:@@experiences.title.of:of`,
      culinaryExperiences: $localize`:@@experiences.title.culinaryExperiences:culinary experiences`,
    },
    subtitle: $localize`:@@experiences.subtitle:Discover and buy tickets to culinary experiences across all cultures.`,
    alt: $localize`:@@experiences.alt:Culinary experiences`,
  },
  chefs: {
    title: $localize`:@@chefs.title:World Class Chefs`,
    subtitle: $localize`:@@chefs.subtitle:Meet some of our most renowned Chefs`,
  },
  mobile: {
    left: {
      title: $localize`:@@mobile.left.title:For our Guests`,
      description: $localize`:@@mobile.left.description:We made it effortless to book the best a city has to offer through a curated collection of culinary experiences.`,
      item1: $localize`:@@mobile.left.item1:Rediscover the world through curated culinary experiences`,
      item2: $localize`:@@mobile.left.item2:Be hosted by truly remarkable chefs`,
      item3: $localize`:@@mobile.left.item3:Buy tickets effortlessly`,
    },
    right: {
      title: $localize`:@@mobile.right.title:For our Chefs`,
      description: $localize`:@@mobile.right.description:We offer a better way to chase that muse by exploring exceptional culinary experiences from renowned Chefs.`,
      item1: $localize`:@@mobile.right.item1:Build your portfolio of culinary masterpieces`,
      item2: $localize`:@@mobile.right.item2:Create and share remarkable menus`,
      item3: $localize`:@@mobile.right.item3:Build a legacy with unique events`,
    }
  },
  venues: {
    alt: $localize`:@@venues.alt:Venues and Organizations`,
    title1: $localize`:@@venues.title1:For our`,
    title2: $localize`:@@venues.title2:Venues`,
    title3: $localize`:@@venues.title3:and`,
    title4: $localize`:@@venues.title4:Organizations`,
    description: $localize`:@@venues.description:Management always on hand. Monetise on premium aspects of your venue that are already there, but currently unexploited. Reduce loss by making no shows irrelevant.`,
    item1: $localize`:@@venues.item1:Ability to have better matchmaking with guests`,
    item2: $localize`:@@venues.item2:Stay relevant to an engaged community of guests`,
    item3: $localize`:@@venues.item3:Operational predictability reduces costs`,
  },
  store: {
    title: $localize`:@@store.title:Seize the moment`,
    description: $localize`:@@store.description:Discover how the future culinary world will look like with 50 Chefs, an all new way of experiencing outstanding culinary creations.`,
  },
  interest: {
    title1: $localize`:@@interest.title1:Expression of`,
    title2: $localize`:@@interest.title2:Interest`,
    description: $localize`:@@interest.description:Would you like to join our community of elite Chefs and discerning Guests and start your quest for the perfect dining experience? Please fill in the form below and we’ll get back to you as soon as possible.`,
    labels: {
      firstName: $localize`:@@interest.labels.firstName:First name*`,
      lastName: $localize`:@@interest.labels.lastName:Last name*`,
      email: $localize`:@@interest.labels.email:Email*`,
      mobile: $localize`:@@interest.labels.mobile:Mobile*`,
      role: $localize`:@@interest.labels.role:What role would you play?*`,
      message: $localize`:@@interest.labels.message:Write your message here...`,
      submit: $localize`:@@interest.labels.submit:Submit`,
    },
    success: $localize`:@@interest.success:Thank you for your interest! We'll get back to you very soon.`,
  },
  footer: {
    poweredBy: $localize`:@@footer.poweredBy:Powered by`,
    terms: $localize`:@@footer.terms:Terms of use`,
    privacy: $localize`:@@footer.privacy:Privacy policy`,
  },
  errors: {
    required: $localize`:@@errors.required:This field is required`,
    email: $localize`:@@errors.email:Please provide a valid email address`,
    phone: $localize`:@@errors.phone:The phone number must contain only digits and must start with the country code`,
    phoneLength: $localize`:@@errors.phoneLength:The phone number must have between 10 and 15 digits`,
    interest: $localize`:@@errors.interest:There has been an error. Please submit the request again`,
  },
  snackbar: {
    info: $localize`:@@snackbar.info:Info`,
    success: $localize`:@@snackbar.success:Success`,
    error: $localize`:@@snackbar.error:Error`
  },
  termsAndConditions: {
    title: $localize`:@@termsAndConditions.title:Terms of Use`,
    description: $localize`:@@termsAndConditions.description:The following Terms of Use outline your obligations when using 50 Chefs websites, mobile applications, technology, and services. In these Terms of Use, 50 Chefs is referred to as “50 Chefs,” “we,” “us,” or “our”; and you are referred to as “you” or “your.”`,

    section1: {
      title: $localize`:@@termsAndConditions.section1.title:1. ACCEPTANCE OF TERMS`,
      content: $localize`:@@termsAndConditions.section1.content:The 50 Chefs Internet site available at www.50chefs.com, the 50 Chefs Mobile Apps, all related sites and mobile applications, and the various content, features, technology, and services offered on and in connection with these sites and applications (collectively, the "Sites and Services") are owned and operated by Cain Group and can only be accessed and used by you under the Terms of Use described below ("Terms of Use").

PLEASE READ THESE TERMS OF USE CAREFULLY. BY ACCESSING, DOWNLOADING, OR USING ANY OF THE SITES AND SERVICES, YOU AGREE TO BECOME BOUND BY THESE TERMS AND CONDITIONS. IF YOU DO NOT AGREE TO ALL THE TERMS AND CONDITIONS, THEN YOU MAY NOT ACCESS AND USE THE SITES AND SERVICES.
`,
    },

    section2: {
      title: $localize`:@@termsAndConditions.section2.title:2. MODIFICATIONS OF TERMS OF USE`,
      content: $localize`:@@termsAndConditions.section2.content:50 Chefs may, in its sole discretion, modify these Terms of Use at any time effective upon posting the modified Terms of Use on and in connection with the Sites and Services, with or without additional notice to you. You are responsible for regularly reviewing information posted on the Sites and Services to obtain timely notice of such changes, and if you do not agree to the amended terms, you agree to immediately stop using the Sites and Services and to provide 50 Chefs notice to remove you from any distribution lists or other communication list that are available to you through your use of the Sites and Services.

YOUR CONTINUED USE OF THE SITES AND SERVICES AFTER SUCH POSTING (OR OTHER NOTIFICATION, IF ANY) MEANS YOU ACCEPT AND AGREE TO BE BOUND BY THE MODIFIED TERMS OF USE.
`,
    },
    section3: {
      title: $localize`:@@termsAndConditions.section3.title:3. USE OF THE SITES AND SERVICES`,
      content: $localize`:@@termsAndConditions.section3.content:Subject to full compliance with these Terms of Use,  50 Chefs grants you a nonexclusive, non-transferable, non-sublicensable, terminable license to access and use the Sites and Services for your personal use. You agree to not access, reproduce, duplicate, copy, sell, resell, modify, distribute, transmit, or otherwise exploit the Sites and Services or any of their Content for any purpose except for your personal use and as described in these Terms of Use, without the express written consent of  50 Chefs.  50 Chefs  may modify, update, suspend or discontinue the Sites and Services, in whole or in part, in its sole discretion for any or no reason, at any time and with or without notice. 50 Chefs shall not be liable to you or any other party for any such modification, update, suspension, or discontinuance.`,
    },
    section4: {
      title: $localize`:@@termsAndConditions.section4.title:4. USER CONDUCT`,
      content: $localize`:@@termsAndConditions.section4.content:As a condition of your access and use of the Sites and Services and your submission or access to any Content, you agree not to use the Sites and Services for any purpose that is unlawful or prohibited by these Terms of Use, or any other purpose not reasonably intended by 50 Chefs. As used herein, “Content” means any ratings, reviews, communications, information, data, text, photographs, audio clips, audiovisual works, or other materials, that are submitted to or accessed from 50 Chefs and/or its Sites and Services. By way of example, and not as a limitation, you agree not to:`,
      subsections: {
        s1: $localize`:@@termsAndConditions.section4.subsections.s1:intentionally or unintentionally violate these Terms of Use, other applicable agreement with  50 Chefs, and any applicable local, state, national or international law, and any rules and regulations having the force of law;`,
        s2: $localize`:@@termsAndConditions.section4.subsections.s2:use the Sites and Services in any manner that violates any relevant law or that infringes, misappropriates, or violates any third party's rights, including, but not limited to, transmitting any Content that may infringe, misappropriate or violate a third party's rights of publicity, contractual rights, fiduciary rights or intellectual property rights;`,
        s3: $localize`:@@termsAndConditions.section4.subsections.s3:use the Sites and Services or its Content for any purposes not authorized by these Terms of Use, including commercial, political, or religious purposes, including the submission or transmission of any Content that contains advertisements, promotional materials, junk mail, or any other form of solicitation;`,
        s4: $localize`:@@termsAndConditions.section4.subsections.s4:reproduce, duplicate, copy, modify, sell, resell or exploit any Content or the Sites and Services for any commercial, educational, or any other non-personal purpose, without the express written consent of  50 Chefs, which consent may be withheld by  50 Chefs  in its sole discretion;`,
        s5: $localize`:@@termsAndConditions.section4.subsections.s5:post irrelevant Content, repeatedly post the same or similar Content or otherwise impose an unreasonable or disproportionately large load on 50 Chefs’s infrastructure, interfere or attempt to interfere with the proper working of the Sites and Services or any activities conducted on the Sites and Services;`,
        s6: $localize`:@@termsAndConditions.section4.subsections.s6:harass, threaten, intimidate, impersonate, or attempt to impersonate, any other person, falsify your contact or other information, misrepresent a relationship with any person or entity, including misrepresenting a relationship with 50 Chefs, or otherwise attempt to mislead others as to the identity of the sender or the origin of Content you submit;`,
        s7: $localize`:@@termsAndConditions.section4.subsections.s7:knowingly provide or submit false or misleading information;`,
        s8: $localize`:@@termsAndConditions.section4.subsections.s8:use the Sites and Services if you are under the age of eighteen (18);`,
        s9: $localize`:@@termsAndConditions.section4.subsections.s9:take any action that would undermine the review and rating process under the Sites and Services;`,
        s10: $localize`:@@termsAndConditions.section4.subsections.s10:attempt to gain unauthorized access to the Sites and Services, other user accounts, or other computer systems or networks connected to the Sites and Services;`,
        s11: $localize`:@@termsAndConditions.section4.subsections.s11:use the Sites and Services in any way that could interfere with the rights of 50 Chefs or the rights of other users of the Sites and Services;`,
        s12: $localize`:@@termsAndConditions.section4.subsections.s12:attempt to gain unauthorized access to any portion or feature of the Sites and Services, or any other systems or networks connected to the Sites and Services or to any server used by 50 Chefs  by hacking password 'mining' or any other illegitimate or unauthorized means, including attempting to obtain password, account, or any other personal or private information from any other Sites and Services user;`,
        s13: $localize`:@@termsAndConditions.section4.subsections.s13:sell, share, or otherwise transfer your account username, password, other information, or your rights or obligations under these Terms of Use;`,
        s14: $localize`:@@termsAndConditions.section4.subsections.s14:transmit or submit any transmission or other materials that are encrypted or that contain viruses, Trojan horses, worms, time bombs, spiders, cancelbots, or other computer programming routines that are likely or intended to damage, interfere with, disrupt, impair, disable or otherwise overburden the Sites and Services;`,
        s15: $localize`:@@termsAndConditions.section4.subsections.s15:access, download, monitor, or copy any information contained on our Sites and Services through artificial means (including but not limited to use any 'deep-link', 'scraper', 'robot', 'spider' or another automatic device, program, algorithm or methodology, or any similar or equivalent automatic or manual process, or in any way reproduce or circumvent the navigational structure or presentation of the Sites and Services or any content, to obtain or attempt to obtain any Content, materials, documents or information through any means not purposely made available through the Sites and Services;`,
        s16: $localize`:@@termsAndConditions.section4.subsections.s16:probe, scan or test the vulnerability of the Sites and Services or any network connected to the Sites and Services, or breach the security or authentication measures on or of the Sites and Services or any network connected to the Sites and Services. You may not reverse look-up, trace, or seek to trace any information on any other user of the Sites and Services, or any other customer of 50 Chefs, including any 50 Chefs account not owned by you, to its source, or exploit the Sites and Services or any service or information made available or offered by or through the Sites and Services, in any way where the purpose is to reveal any information, including but not limited to personal identification information or information other than your own information, except as expressly authorized by 50 Chefs and provided for by the Sites and Services; or`,
        s17: $localize`:@@termsAndConditions.section4.subsections.s17:reverse engineer, decompile, or disassemble any of the Sites and Services by any means whatsoever, or alter, modify, or create a derivative work of the Sites and Services. You may not remove, alter, or obscure any product identification, copyright, or other intellectual property notices on the Sites and Services.`,
      }
    },
    section5: {
      title: $localize`:@@termsAndConditions.section5.title:5. 50 Chefs ACCOUNT AND OTHER 50 Chefs SERVICES`,
      content: $localize`:@@termsAndConditions.section5.content:When using, accessing, or purchasing particular services or features of the Sites and Services, you shall be subject to any posted agreements, guidelines, or rules applicable to such services or features that may be posted from time to time. All such agreements, guidelines, or rules are hereby incorporated by reference into these Terms of Use.`,
    },
    section6: {
      title: $localize`:@@termsAndConditions.section6.title:6. REGISTRATION INFORMATION`,
      content: $localize`:@@termsAndConditions.section6.content:We may require that you create an account to use or access certain parts of the Sites and Services and use certain products and features. We may require that you provide login information such as an email and password to access and utilize your account. As a condition of your use of the Sites and Service, you agree to (a) provide 50 Chefs  with true, accurate, current, and complete information as prompted by the 50 Chefs  registration forms, when registering for or using the Sites and Services, (b) update and maintain the truthfulness, accuracy, and completeness of such information and (c ) You agree not to share personal health information such as diagnoses, treatment information, medical test results or prescription information. You are responsible for maintaining the confidentiality of any password or other account information not generally available to others and you are fully responsible for all activities that occur under your username and password. While there are limited, legitimate reasons for creating multiple accounts, creating serial or overlapping accounts may result in account termination in 50 Chefs’s sole discretion. Please contact us if you have questions about managing multiple accounts.`,
    },
    section7: {
      title: $localize`:@@termsAndConditions.section7.title:7. SUBMITTING CONTENT`,
      content: $localize`:@@termsAndConditions.section7.content:As a condition of you submitting any Content or other materials to the Sites or Services, you agree that:`,
      subsections: {
        s1: $localize`:@@termsAndConditions.section7.subsections.s1:you (i) grant to 50 Chefs a royalty-free, perpetual, irrevocable, worldwide, nonexclusive, transferable, and sublicensable license to use, reproduce, copy, adapt, modify, merge, distribute, and publicly display such Content, create derivative works from such Content, and incorporate such Content into other works; (ii) grant to 50 Chefs all rights necessary to publish or refrain from publishing your name and address in connection with Content you submit to 50 Chefs; (iii) grant to 50 Chefs the right to sublicense the foregoing Content and related rights through multiple tiers, and (iv) acknowledge that this license cannot be terminated by you once your Content is submitted to the Sites and Services;`,
        s2: $localize`:@@termsAndConditions.section7.subsections.s2:you grant to 50 Chefs all rights necessary to prohibit the subsequent aggregation, display, copying, duplication, reproduction, distribution, or exploitation of your Content by any other party;`,
        s3: $localize`:@@termsAndConditions.section7.subsections.s3:you represent that you own or have secured all legal rights necessary for the Content submitted by you to be used by you, 50 Chefs, and others as described and otherwise contemplated in these Terms of Use;`,
        s4: $localize`:@@termsAndConditions.section7.subsections.s4:you represent and warrant that each person identified, depicted, or shown in your Content, if any, (and if a minor, the parent or guardian of the minor) has provided consent to the use of the Content consistent with these Terms of Use;`,
        s5: $localize`:@@termsAndConditions.section7.subsections.s5:you are solely responsible for your content, posts, messages, reviews, and ratings;`,
        s6: $localize`:@@termsAndConditions.section7.subsections.s6:50 Chefs may, in its sole discretion, choose to remove or not to remove content once published;`,
        s7: $localize`:@@termsAndConditions.section7.subsections.s7:you will not submit any content that may be considered by 50 Chefs, in its sole discretion, to be infringing, harassing, libelous, abusive, threatening, obscene, profane, hateful, offensive, harmful, vulgar, distasteful, defamatory, or racially, ethnically or otherwise objectionable, or that violates any relevant law or right of any other party;`,
        s8: $localize`:@@termsAndConditions.section7.subsections.s8:you will not submit content with hyperlinks; and`,
        s9: $localize`:@@termsAndConditions.section7.subsections.s9:the content that you provide does not reflect the views of 50 Chefs, its officers, managers, owners, employees, agents, designees, or other users.`,
      }
    },
    section8: {
      title: $localize`:@@termsAndConditions.section8.title:8. AFFILIATES`,
      content: $localize`:@@termsAndConditions.section8.content:From time to time, 50 Chefs may promote, affiliate with, or partner with other individuals or businesses whose programs, products, and services align. 50 Chefs may receive financial compensation or other rewards. Users are still required to use your own judgment to determine that any such program, product, or service is appropriate for you. You are assuming all risks, and you agree that 50 Chefs is not liable in any way for any program, product, or service that It may promote, market, share or sell on or through its Sites and Services.`,
    },
    section9: {
      title: $localize`:@@termsAndConditions.section9.title:9. PUBLICATION AND DISTRIBUTION OF CONTENT`,
      content: $localize`:@@termsAndConditions.section9.content:50 Chefs cannot and does not guarantee the accuracy, integrity, quality, or appropriateness of any Content transmitted to or through the Sites and Services. You acknowledge that 50 Chefs acts as a passive conduit and an interactive computer service provider with respect to the publication and distribution of user-generated Content. You understand that all Content posted on, transmitted through, or linked through the Service, is the sole responsibility of the person from whom such Content originated. You understand that 50 Chefs does not control, and is not responsible for user-generated Content made available through the Sites and Services and that by using the Sites and Services, you may be exposed to Content that is inaccurate, misleading, or offensive. You agree that you must evaluate and make your own judgment, and bear all risks associated with the use of any Content.
You further acknowledge that 50 Chefs has no obligation to screen, preview, monitor, or approve any user-generated Content on the Sites and Services. However, 50 Chefs reserves the right to review, modify, and/or delete any Content that, in its sole judgment, violates these Terms of Use. By using the Sites and Services, you agree that it is solely your responsibility to evaluate and bear any risks associated with the use, accuracy, usefulness, completeness, or appropriateness of any Content that you submit, receive, access, transmit, or otherwise convey through the Sites and Services. Under no circumstances will 50 Chefs be liable in any way for any Content, including, but not limited to, any Content that contains any errors, omissions, defamatory statements, or confidential or private information or for any loss or damage of any kind incurred as a result of the use of any Content submitted, accessed, transmitted or otherwise conveyed via the Sites and Services. You waive the right to bring or assert any claim against 50 Chefs or any of its affiliates relating to Content, and you release 50 Chefs and its affiliates from any and all liability for or relating to any Content. 50 Chefs has no obligation to review, monitor, or remove any Content or other materials, except as required by law.
You may, however, report Content that you believe violates these Terms of Use or is otherwise unlawful by sending an email to legal@50chefs.com (for copyright complaints, please see below). Please note that you may be liable for damages (including costs and attorneys’ fees) for unlawful misrepresentations. If you are uncertain whether an activity is unlawful, we recommend seeking advice from an attorney.
You agree that 50 Chefs may establish general practices, policies and limits, which may or may not be published, concerning the use of the Sites and Services, including without limitation, the time that Content will be retained, the maximum number of Content that may be submitted from an account, the length of Content sent, and the maximum number of times and the maximum duration for which you may access the Sites and Services in a given period of time. You agree that 50 Chefs has no responsibility or liability for the deletion or failure to store any Content or other materials maintained or transmitted by or through the Sites and Services. You agree that 50 Chefs has the right to change these general practices and limits at any time, in its sole discretion, with or without notice.
`,
    },
    section10: {
      title: $localize`:@@termsAndConditions.section10.title:10. SERVICE PROVIDERS`,
      content: $localize`:@@termsAndConditions.section10.content:50 Chefs does not endorse and is not responsible or liable for any Content, data, advertising, products, goods or services available or unavailable from, or through, any third party or Service Provider. You agree that, should you use or rely on such Content, data, advertisement, products, goods or services, available or unavailable from, or through any third party or Service Provider, 50 Chefs is not responsible or liable, indirectly or directly, for any damage or loss caused or alleged to be caused by or in connection with such use or reliance. Your dealings with, or participation in promotions of any Service Provider, and any other terms, conditions, representations, or warranties associated with such dealings, are between you and such Service Provider exclusively and do not involve 50 Chefs. You should make whatever investigation or other resources that you deem necessary or appropriate before hiring or engaging Service Providers.
Third parties and Service Providers may link or otherwise direct Internet users to our Sites and Services for the purpose of utilizing one or more of the services we provide on behalf of others. Additionally, we may provide links or otherwise direct you to third-party or Service Provider websites. 50 Chefs does not control or operate any such third party or Service Provider websites. Any information you provide to these third-party or Service Provider websites while on these third party or Service Provider websites is subject to the respective policies of those third parties or Service Providers, and not 50 Chefs's policies. It is your responsibility to review such third party or Service Provider policies, including any relevant privacy policies. You agree that 50 Chefs will not be responsible or liable for, and does not endorse any content, advertising, goods, or services provided on or through these outside websites or for your use or inability to use such websites. 50 Chefs does not make any representations or warranties as to the security of any information (including, without limitation, credit card and other personal information) you might be requested to give to any third party or Service Provider sites. You use these third party or Service Provider websites at your own risk.
You (a) agree that 50 Chefs is not responsible for the accessibility or unavailability of any Service Provider or for your interactions and dealings with them, (b) waive the right to bring or assert any claim against 50 Chefs relating to any interactions or dealings with any Service Provider, and (c) release 50 Chefs from any and all liability for or relating to any interactions or dealings with Service Providers. Without limiting the foregoing, your correspondence or business dealings with, consumption of products or services of, or participation in promotions of, third parties or Service Providers found on or through the use of the Sites and Services, including payment for and delivery or fulfillment of related goods or services, and any other terms, conditions, warranties, or representations associated with such dealings, are solely between you and such third party or Service Provider. You agree that 50 Chefs shall not be responsible or liable, directly or indirectly, for any loss or damage of any sort incurred as the result of any such dealings or as the result of the presence of such third parties or Service Providers on the 50 Chefs  Sites and Services.
`,
    },
    section11: {
      title: $localize`:@@termsAndConditions.section11.title:11. TERMINATION OF ACCESS`,
      content: $localize`:@@termsAndConditions.section11.content:50 Chefs may terminate your privilege to use or access the Sites and Services immediately and without notice for any reason whatsoever. Upon such termination, you must immediately cease accessing or using the Sites and Services and agree not to access or make use of, or attempt to use, the Sites and Services. Furthermore, you acknowledge that 50 Chefs reserves the right to take all actions, technical, legal, or otherwise, to block, nullify, or deny your ability to access the Sites and Services. You understand that 50 Chefs may exercise these rights in its sole discretion, and these rights shall be in addition to and not in substitution for any other rights and remedies available to 50 Chefs.
All provisions of these Terms of Use which by their nature should survive termination shall survive the termination of your access to the Sites and Services, including, without limitation, provisions regarding ownership, warranty disclaimers, indemnity, and limitations of liability.
`,
    },
    section12: {
      title: $localize`:@@termsAndConditions.section12.title:12. INTELLECTUAL PROPERTY`,
      content: $localize`:@@termsAndConditions.section12.content:The Sites and Services are protected by copyright, trademark, and other intellectual property laws and regulations. 50 Chefs owns the entire right, title, and interest in and to the Sites and Services and in and to any and all associated intellectual property rights, worldwide.
50 Chefs, and other Sites and Services’ graphics, images, logos, designs, page headers, button icons, scripts, domain names, trade names, service names, and other source identifiers are the trademarks, service marks, and trade dress of 50 Chefs in United Arab Emirates and foreign countries. You are not permitted to use such trademarks, trade dress, or other intellectual property rights of 50 Chefs, including as part of other trademarks and/or domain names, keyword advertisements, or email addresses, or in connection with any product or service in any manner that is likely to cause confusion.
You should assume all Content and materials made available on the Sites and Services is protected by copyright law and/or other intellectual property law. Aside from user-submitted Content, all other materials and other information on the Sites and Services, including, but not limited to, all text, graphics, logos, icons, images, audio and video clips, downloads, data compilations, and software are the exclusive property of 50 Chefs and/or its licensors and are protected by all United Arab Emirates and international copyright and other intellectual property laws.
`,
    },
    section13: {
      title: $localize`:@@termsAndConditions.section13.title:13. DELAYS AND ACCESSIBILITY`,
      content: $localize`:@@termsAndConditions.section13.content:The Sites and Services may be subject to limitations, delays, failure, and other problems inherent in the use of the Internet and electronic communications. 50 Chefs is not responsible for any delays, failures, or other damage resulting from such problems.`,
    },
    section14: {
      title: $localize`:@@termsAndConditions.section14.title:14. USER FEEDBACK`,
      content: $localize`:@@termsAndConditions.section14.content:50 Chefs appreciates hearing from you, as well as our other users, and welcomes your comments regarding our Sites and Services. Please be advised, however, that our policy does not permit us to accept or consider creative ideas, suggestions, or materials other than those which we have specifically requested in writing. Although we do value your feedback on our Sites and Services, please do not submit creative ideas, suggestions, or materials. If despite our request, you send us creative suggestions, ideas, drawings, concepts, or other information (collectively, the "Submissions"), such Submissions will be the property of 50 Chefs. In addition, none of the Submissions will be subject to any obligations of confidentiality, and 50 Chefs  will not be liable for any future use or disclosure of such Submissions by 50 Chefs or any other party.`,
    },
    section15: {
      title: $localize`:@@termsAndConditions.section15.title:15. INDEMNITY`,
      content: $localize`:@@termsAndConditions.section15.content:You agree to indemnify, defend and hold harmless 50 Chefs, its affiliates, and their officers, directors, managers, owners, employees, agents, designees, users, successors, assigns, service providers, and suppliers from and against all losses, liabilities, expenses, damages, claims, demands and costs, including reasonable attorneys' fees and court costs due to or arising from: (a) any violation of these Terms of Use by you; (b) the inaccurate or untruthful Content or other information provided by you to 50 Chefs or that you submit, transmit or otherwise make available through the Sites and Services; or (c) any intentional or willful violation of any rights of another or harm you may have caused to another. 50 Chefs  will have sole control of the defense of any such damage or claim.`,
    },
    section16: {
      title: $localize`:@@termsAndConditions.section16.title:16. NOTICE`,
      content: $localize`:@@termsAndConditions.section16.content:By use of the Sites and Services, you consent to receive electronic communications from 50 Chefs (via email or via a posting on the Sites), and you agree that any such communications satisfy any legal requirement to make such communications in writing. You also agree that 50 Chefs may communicate any notices to you under these Terms of Use, through electronic mail, regular mail, or posting the notices on the Sites and Services. All notices to 50 Chefs will be provided by either sending: (i) an email to  legal@50chefs.com; or (ii) a letter, first class certified mail, to Dubai Office, Cain Group FZ LLE, Office 404, Building B, Al Saaha Offices, Souk Al Bahar, Old Town Island, Burj Khalifa District, PO Box 487177, Dubai – UAE Attn: 50 Chefs Member Services. Such notices will be deemed delivered upon the earlier of the verification of delivery or two (2) business days after being sent.`,
    },
    section17: {
      title: $localize`:@@termsAndConditions.section17.title:17. ENTIRE AGREEMENT`,
      content: $localize`:@@termsAndConditions.section17.content:These Terms of Use and other agreements, rules, and policies incorporated by reference to these Terms of Use including, without limitation, the Privacy Policy, constitutes the entire agreement between you and 50 Chefs. It supersedes any prior or contemporaneous negotiations, discussions, or agreements, whether written or oral, between you and 50 Chefs regarding the subject matter contained in these Terms of Use. Additional terms and conditions may exist between you and third parties, including but not limited to, Service Providers and others. You represent and warrant that those third party agreements do not interfere with your obligations and duties to 50 Chefs under these Terms of Use.`,
    },
    section18: {
      title: $localize`:@@termsAndConditions.section18.title:18. GOVERNING LAW`,
      content: $localize`:@@termsAndConditions.section18.content:These Terms of Use and the relationship between you and 50 Chefs will be governed by the laws of the United Arab Emirates, notwithstanding the choice of law provisions of the venue where any action is brought, where the violation occurred, where you may be located, or any other jurisdiction. You agree and consent to the exclusive jurisdiction of the courts located in United Arab Emirates and waive any defense of lack of personal jurisdiction or improper venue or forum non conveniens to a claim brought in such courts, except that 50 Chefs may elect, in its sole discretion, to litigate the action in the county or state where any breach by you occurred or where you can be found. You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out or related to your use of the Sites and Services or these Terms of Use shall be filed within one (1) year after such claim or cause of action arose or will forever be barred.`,
    },
    section19: {
      title: $localize`:@@termsAndConditions.section19.title:19. MISCELLANEOUS`,
      content: $localize`:@@termsAndConditions.section19.content:If you breach any term of these Terms of Use or other agreement with 50 Chefs, 50 Chefs  may pursue any legal or equitable remedy available, including but not limited to, direct, consequential, and punitive damages and injunctive relief. 50 Chefs's remedies are cumulative and not exclusive. Failure of 50 Chefs to exercise any remedy or enforce any portion of these Terms of Use at any time shall not operate as a waiver of any remedy or of the right to enforce any portion of the Agreement at any time thereafter. If any provision of these Terms of Use is found to be unenforceable or invalid, that provision shall be limited or eliminated to the minimum extent necessary so that these Terms of Use shall otherwise remain in full force and effect and enforceable. These Terms of Use are not assignable, transferable, or sublicensable by you except with 50 Chefs's prior written consent. We may transfer, assign, or delegate the rights and obligations under these Terms of Use in whole or in part without your consent. Users of the Sites and Services are responsible for compliance with all applicable regulations and laws. No joint venture, partnership, employment, or agency relationship exists between you and 50 Chefs as a result of these Terms of Use or use of the Sites and Services. You acknowledge and agree that each of the parties released under Section 11 shall be an intended third-party beneficiary of these Terms of Use.`,
    },
    section20: {
      title: $localize`:@@termsAndConditions.section20.title:20. PURCHASES`,
      content1: $localize`:@@termsAndConditions.section20.content1:Fees: 50 Chefs offers the option to book tickets for participating in certain events (“Transaction”). When you purchase a Transaction, we may ask you to supply additional information relevant to your Transaction, such as your credit card number, the expiration date of your credit card and your credit card’s CVC (such information, “Payment Information”). You represent and warrant that you have the legal right to use all payment method(s) represented by any such Payment Information. The amounts due and payable by you for a Transaction through the Services will be presented to you before you confirm and pay your booking. If you choose to initiate a Transaction via the Services, you authorize us to provide your Payment Information to third-party service providers (`,
      stripe: $localize`:@@termsAndConditions.section20.stripe:Stripe`,
      content2: $localize`:@@termsAndConditions.section20.content2:, as “Payment Provider”) so we can complete your Transaction and agree (a) to pay the applicable fees and any taxes; (b) that 50 Chefs may charge your credit card using services from the Payment Provider; and (c) to bear any additional charges that your bank or another financial service provider may levy on you as well as any taxes or fees that may apply to your booking. You’ll receive a confirmation email after we confirm the payment for your order. Your order is not binding on 50 Chefs until accepted and confirmed by 50 Chefs. All payments made are non-refundable and non-transferable except as expressly provided in these Terms. If you have any concerns or objections regarding charges, you agree to raise them with us first and you agree not to cancel or reject any credit card or third-party payment processing charges unless you have made a reasonable attempt at resolving the matter directly with 50 Chefs. 50 Chefs reserves the right to not process or to cancel your booking in certain circumstances, for example, if your credit card is declined, if we suspect the request or order is fraudulent, or in other circumstances 50 Chefs deems appropriate in its sole discretion. 50 Chefs also reserves the right, in its sole discretion, to take steps to verify your identity in connection with your booking. You may need to provide additional information to verify your identity before completing your Transaction (such information is included within the definition of Payment Information). 50 Chefs will either not charge you or refund the charges for bookings that we do not process or cancel.`,
    },
    section21: {
      title: $localize`:@@termsAndConditions.section21.title:21. CONTACTING US`,
      content: $localize`:@@termsAndConditions.section21.content:If you have any comments or questions regarding these Terms of Use or wish to report any violation of these Terms of Use, please contact us at legal@50chefs.com. We will address any issue to the best of our abilities.`,
    },
  },

  privacyPolicy: {
    title: $localize`:@@privacyPolicy.title:Privacy Policy`,
    description: $localize`:@@privacyPolicy.description:This Privacy Policy discloses our privacy practices and the possible uses of the information that we gather via www.50chefs.com, 50 Chefs Mobile Apps, and via other submissions by you to 50 Chefs (together, the "Site and Services"). By using 50 Chefs you consent to the terms of this Privacy Policy. If you do not agree to the terms and conditions of this Privacy Policy, including having your personally identifiable information ("Personal Information" as defined below) used in any of the ways described in this Privacy Policy, you may not be able to use certain parts or features of our Site and Services, and in some instances, this may necessitate the revocation of your membership. 50 Chefs may update this Privacy Policy from time to time at our sole discretion. It is your responsibility to review the Privacy Policy for any changes each time that you use the Site and Services and you are bound by such changes. Use of the Site and Services by you following us posting a new privacy policy on our Site constitutes your acceptance of the Privacy Policy as modified.

This Privacy Policy applies to the entire 50 Chefs family of products and services, such as our online services, our mobile apps, and other 50 Chefs products or services.`,

    section1: {
      title: $localize`:@@privacyPolicy.section1.title:1. Information Collected`,
      content: $localize`:@@privacyPolicy.section1.content:50 Chefs collects information on our users in various ways, such as, by your voluntary submissions, participation in services provided via the Site and Services, from third parties, and through cookie and other tracking technology. 50 Chefs collects the following information:`,
      subsections: {
        s1: {
          title: $localize`:@@privacyPolicy.section1.subsections.s1.title:Personal Information`,
          content: $localize`:@@privacyPolicy.section1.subsections.s1.content:"Personal Information" refers to information that tells us specifically who you are, such as your name, phone number, email, and possibly information relating to certain support or customer service issues. In many cases, we need this information to provide you many of the services that you may have requested.`,
        },
        s2: {
          title: $localize`:@@privacyPolicy.section1.subsections.s2.title:Submission of Reviews, Ratings, and Comments`,
          content: $localize`:@@privacyPolicy.section1.subsections.s2.content:We collect information that you voluntarily provide as part of our Site and Services, including messages, posts, comments, responses, reviews, and ratings you submit through our Site and Services, ("User Generated Content"). 50 Chefs may in our sole discretion share your User Generated Content with others such as your reviews. If you do not want certain information shared with others, you should not submit User Generated Content, or should not submit User Generated Content that contains information or Personal Information you do not want to be shared in this manner. Once you have submitted User Generated Content, we reserve the right to publish it in any medium to others.`,
        },
        s3: {
          title: $localize`:@@privacyPolicy.section1.subsections.s3.title:Aggregate Information`,
          content: $localize`:@@privacyPolicy.section1.subsections.s3.content:We may collect general, non-personal, statistical information about the use of the Site and Services, such as how many visitors visit a specific page on the Site, how long they stay on that page, and which hyperlinks, if any, they click on. This information represents a generic overview of our users, including their collective viewing habits, and allows us and other third parties to modify information, promotions, offers, and/or discounts on products and services based on user traffic and behavior. We collect this information through the use of technologies such as 'cookies' and web beacons, which are discussed in greater detail below. We collect this information in order to help enhance the Site and Services to make for a better visitor experience.`,
        },
        s4: {
          title: $localize`:@@privacyPolicy.section1.subsections.s4.title:Third-Party Information`,
          content: $localize`:@@privacyPolicy.section1.subsections.s4.content:We may supplement the information you provide to us with information from other sources, such as information to validate or update your address, payment card, or other demographic and lifestyle information. We use this information to help us maintain the accuracy of the information we collect, to enable us to fulfill our products and services, to target our communications so that we can inform you of products, services and offers that may be of interest, and for internal business analysis or other business purposes consistent with our mission.`,
        },
        s5: {
          title: $localize`:@@privacyPolicy.section1.subsections.s5.title:Other Tracking Technologies`,
          content: $localize`:@@privacyPolicy.section1.subsections.s5.content:Tracking technologies may record information such as Internet domain and host names; Internet protocol (IP) addresses; browser software and operating system types; clickstream patterns; and dates and times that our Site and Services are accessed. An IP address is a number that is automatically assigned to your computer whenever you are surfing the web. Web servers, the computers that 'serve up' web pages, automatically identify your computer by its IP address. The Site and Services may also use a technology called "tracer tags". These may also be referred to as "Pixels", "Clear GIFs" or "Web Beacons". This technology allows us to understand which pages you visit on our Site and other ways you interact with our Site and Services, such as purchases made through the Site & mobile app from Play Store or App Store and Services. These tracer tags are used to help us to optimize and tailor our Site and Services for you and other users of our Site and Services. We may link the information we record using tracking technologies to the Personal Information we collect.`,
        }
      }
    },
    section2: {
      title: $localize`:@@privacyPolicy.section2.title:2. Use of Information`,
      content: $localize`:@@privacyPolicy.section2.content:The information 50 Chefs collects is used in a variety of ways, including:`,
      subsections: {
        s1: $localize`:@@privacyPolicy.section2.subsections.s1:for internal review;`,
        s2: $localize`:@@privacyPolicy.section2.subsections.s2:to improve the Site and Services;`,
        s3: $localize`:@@privacyPolicy.section2.subsections.s3:to optimize third-party offers of products and/or services;`,
        s4: $localize`:@@privacyPolicy.section2.subsections.s4:to verify the legitimacy of reviews and ratings;`,
        s5: $localize`:@@privacyPolicy.section2.subsections.s5:to notify you about updates to the Site and Services;`,
        s6: $localize`:@@privacyPolicy.section2.subsections.s6:to let you know about products, services, and promotions that you may be interested in;`,
        s7: $localize`:@@privacyPolicy.section2.subsections.s7:to get reviews and ratings;`,
        s8: $localize`:@@privacyPolicy.section2.subsections.s8:to fulfill and provide products and services, including personalized or enhanced services, requested by you;`,
        s9: $localize`:@@privacyPolicy.section2.subsections.s9:internal business analysis or other business purposes consistent with our mission; and to carry out other purposes that are disclosed to you;`,
        s10: $localize`:@@privacyPolicy.section2.subsections.s10:Transactional Information: When you make a purchase, we collect information about the transaction, such as product description, price, subscription, and time and date of the transaction.`,
      }
    },
    section3: {
      title: $localize`:@@privacyPolicy.section3.title:User Rights`,
      content: $localize`:@@privacyPolicy.section3.content:Each user has the right:`,
      subsections: {
        s1: $localize`:@@privacyPolicy.section3.subsections.s1:to be informed; This means anyone processing your personal data must make clear what they are processing, why, and who else the data may be passed to;`,
        s2: $localize`:@@privacyPolicy.section3.subsections.s2:of access; this is your right to see what data is held about you by a Data Controller;`,
        s3: $localize`:@@privacyPolicy.section3.subsections.s3:to rectification; the right to have your data corrected or amended if what is held is incorrect in some way;`,
        s4: $localize`:@@privacyPolicy.section3.subsections.s4:to erasure; under certain circumstances, you can ask for your personal data to be deleted. This is also called ‘the Right to be Forgotten’. This would apply if the personal data is no longer required for the purposes it was collected for, or your consent for the processing of that data has been withdrawn, or the personal data has been unlawfully processed;`,
        s5: $localize`:@@privacyPolicy.section3.subsections.s5:to restrict processing; this gives the Data Subject the right to ask for a temporary halt to processing of personal data, such as in the case where a dispute or legal case has to be concluded, or the data is being corrected;`,
        s6: $localize`:@@privacyPolicy.section3.subsections.s6:to data portability; a Data Subject has the right to ask for any data supplied directly to the Data Controller by him or her, to be provided in a structured, commonly used, and machine-readable format;`,
        s7: $localize`:@@privacyPolicy.section3.subsections.s7:to object; the Data Subject has the right to object to the further processing of their data which is inconsistent with the primary purpose for which it was collected, including profiling, automation, and direct marketing;`,
        s8: $localize`:@@privacyPolicy.section3.subsections.s8:in relation to automated decision making and profiling; Data Subjects have the right not to be subject to a decision based solely on automated processing.`,
      }
    },
    section4: {
      title: $localize`:@@privacyPolicy.section4.title:4. Disclosures and Transfers of Information`,
      content: $localize`:@@privacyPolicy.section4.content:We do not disclose Personal Information to third parties, except when one or more of the following conditions is true:`,
      subsections: {
        s1: $localize`:@@privacyPolicy.section4.subsections.s1:We have your permission to make the disclosure;`,
        s2: $localize`:@@privacyPolicy.section4.subsections.s2:The disclosure is necessary for the purpose for which the personal information was obtained;`,
        s3: $localize`:@@privacyPolicy.section4.subsections.s3:The disclosure is to financial service providers in order to fulfill and carry out the purchase and provision of goods and services requested by you;`,
        s4: $localize`:@@privacyPolicy.section4.subsections.s4:The disclosure is permitted by relevant law;`,
        s5: $localize`:@@privacyPolicy.section4.subsections.s5:The Personal Information to be disclosed is otherwise publicly available in accordance with the applicable law;`,
        s6: $localize`:@@privacyPolicy.section4.subsections.s6:The disclosure is reasonably related to the sale or other disposition of all or part of our business or assets;`,
        s7: $localize`:@@privacyPolicy.section4.subsections.s7:The disclosure is for our own marketing purposes, or, with your authorization, for the marketing purposes of third parties;`,
        s8: $localize`:@@privacyPolicy.section4.subsections.s8:The party to whom the disclosure is made controls is controlled by, or is under common control with 50 Chefs;`,
        s9: $localize`:@@privacyPolicy.section4.subsections.s9:The disclosure is in our sole discretion necessary for the establishment or maintenance of legal claims or legal compliance, to satisfy any law, regulation, subpoena or government request, or in connection with litigation;`,
        s10: $localize`:@@privacyPolicy.section4.subsections.s10:The disclosure is in our sole discretion necessary or appropriate to prevent harm, fraud, financial loss, or in connection with an investigation of actual or suspected illegal activities;`,
        s11: $localize`:@@privacyPolicy.section4.subsections.s11:The disclosure is in our sole discretion about users who are in violation of the 50 Chefs Terms of Use;`,
        s12: $localize`:@@privacyPolicy.section4.subsections.s12:The disclosure is to outside businesses to perform certain services for us, such as maintaining our Site and Services, mailing lists, delivering products and services, providing marketing assistance, and data analysis ("Administrative Service Providers"), including Administrative Service Providers outside the country or jurisdiction in which you reside.`,
        s13: $localize`:@@privacyPolicy.section4.subsections.s13:Log Information: We collect standard log files when you use our Services, which include the type of web browser you use, app version, access times and dates, pages viewed, your IP address, and the page you visited before navigating to our websites.`,
      },
      content2: $localize`:@@privacyPolicy.section4.content2:If 50 Chefs becomes aware that any outside party is improperly using or disclosing Personal Information, it will notify that outside party and request that it take reasonable steps to cease such use or disclosure.

We may provide certain Personal Information as a service to others for their marketing purposes. We may also disclose your name and address to Service Providers when you submit User Generated Content relating to those Service Providers. If you properly notify us that you no longer want us to share your Personal Information, we will cease sharing your Personal Information.

We may also disclose aggregate visitor data in order to describe the use of the Site and Services to our existing or potential business partners or other third parties, or in response to a government request. We may also share aggregated demographic and preference data with third parties to enable the provision of targeted information, promotions, offers, and/or discounts on products and services.
`,
    },
    section5: {
      title: $localize`:@@privacyPolicy.section5.title:5. Data Security, Integrity, and Access`,
      content: $localize`:@@privacyPolicy.section5.content:The Personal Information we collect is stored within databases that we control directly or through our Administrative Service Providers. As we deem appropriate and as required by the applicable law, we use appropriate security measures, including physical, organizational, and technical protections, to protect your Personal Information against accidental, unlawful or unauthorized destruction, loss, alteration, access, disclosure, or use. We also use encryption technology to protect credit card and password information. However, since no security system is impenetrable, we cannot guarantee the security of our databases, nor can we guarantee that information you supply will not be intercepted while being transmitted to us over the Internet.

We retain Personal Information that we collect only as long as reasonably necessary to fulfill the purposes for which it was collected or to otherwise meet any legal requirements.

Your information may be transferred to and maintained on, computers located outside of your state, province/territory, or country where the privacy laws may not be as protective as those where you live. If you are located outside the United States and choose to provide information to us, please be aware that we transfer Personal Information to the United States and process and store it there. As a result, this information may be subject to access requests from governments, courts, law enforcement officials, and national security authorities in the United States according to its laws. By submitting your Personal Information, you consent to have your personal data transferred to, processed, and stored in the United States.

50 Chefs may assign you a user ID and a password as part of your participation and access to the Site and Services. Your user ID and password may only be used by you and the members of your household. You may not share your user ID and password with anyone else, and you are solely responsible for maintaining and protecting the confidentiality of your user ID and password. You are fully responsible for all activities that occur under your user ID and password. `,
    },
    section6: {
      title: $localize`:@@privacyPolicy.section6.title:6. How to Contact us`,
      content: $localize`:@@privacyPolicy.section6.content:In accordance with CalOPPA, GDPR, and CCPA you are entitled to review, change, or delete the information we store on your account. Due to the personalized nature of our sites and services users wishing to delete their account information will cease to be able to access certain features of the site. By asking to remove your information you acknowledge this fact. Deleted information is by its very nature permanent, by asking to remove your information you acknowledge this fact. All requests, questions, concerns, or complaints about your Personal Information or this Privacy Policy, please contact our Privacy Officer by mail or email at the following:

Attn: 50 Chefs

Dubai Office
Cain Group FZ LLE
Office 404, Building B
Al Saaha Offices, Souk Al Bahar
Old Town Island, Burj Khalifa District
PO Box 487177, Dubai – UAE


E-mail: legal@50chefs.com
Subject: PRIVACY CONCERN

We welcome your questions and feedback.
`,
    }
  }
}
