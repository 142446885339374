import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-button',
  template: `
    <button
      mat-stroked-button
      [type]="type"
      [color]="color"
      [disabled]="disabled || loading"
      (click)="press.emit()"
    >
      <div class="button__label">
        <span class="button-label" [ngClass]="{'button__label--hidden': loading}">{{ label | translate }}</span>
        <app-spinner *ngIf="loading" class="button__spinner"></app-spinner>
      </div>
    </button>
  `,
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() color = 'primary';
  @Input() loading = false;
  @Input() type: string;
  @Input() label: string;

  @Output() press: EventEmitter<void> = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }
}
