import {Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {fromEvent, Subject} from 'rxjs';
import {startWith} from 'rxjs/operators';

@Directive({
  selector: '[appScroll]'
})
export class ScrollDirective implements OnInit, OnDestroy {
  @Input() visibilityPercentage: number;
  @Input() emitOnceOnAppear = true;
  @Output() scrolled = new EventEmitter<number>();
  @Output() appeared = new EventEmitter<void>();

  private ngUnsubscribe = new Subject();

  constructor(private element: ElementRef) {
  }

  ngOnInit(): void {
    this.subscribeToScroll();
  }

  unsubscribeScroll(): void{
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private subscribeToScroll(): void {
    fromEvent(window, 'scroll')
      .pipe(startWith(null))
      .subscribe(() => {
        const rect = this.element.nativeElement.getBoundingClientRect();
        const componentOffset = rect.top + window.innerHeight * this.visibilityPercentage;
        this.scrolled.emit(componentOffset);
        if(this.emitOnceOnAppear && componentOffset < 0){
          this.appeared.emit();
          this.unsubscribeScroll();
        }
      })
  }

  ngOnDestroy(): void {
    this.unsubscribeScroll();
  }
}
