import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FloatLabelType } from '@angular/material/form-field';

@Component({
  selector: 'app-text-area',
  template: `
    <mat-form-field
      [appearance]="appearance"
      [floatLabel]="placeholder ? 'always' : floatLabel"
      color="accent"
      class="full-width"
    >
      <mat-label>{{ label | translate }}</mat-label>
      <textarea
        matInput
        rows="9"
        [placeholder]="placeholder | translate"
        [formControl]="control"
        [maxlength]="maxLength"
        class="textarea"
      ></textarea>

      <mat-error
        class="mat-caption weight-600"
        *ngIf="control?.invalid && control?.hasError('required')"
      >
        {{ 'errors.required' | translate }}
      </mat-error>
    </mat-form-field>
  `,
  styleUrls: ['./text-area.component.scss']
})
export class TextAreaComponent implements OnInit {
  @Input() label: string;
  @Input() control: FormControl;
  @Input() appearance: string;
  @Input() placeholder: string;
  @Input() maxLength: number;
  @Input() floatLabel: FloatLabelType;

  constructor() {}

  ngOnInit(): void {}
}
